<script>
	import { tick } from "svelte";

	export let text = "Le Balcon";
	// export let side = "left";
	
	let start = 0;

	// $: start = side === "left" 
	// 		? 0 
	// 		: side === "center" 
	// 			? Math.floor(text.length / 2)
	// 			: text.length - 1;

	function getFont(i) {
		return Math.abs(Math.floor((i - start) / (text.length - 1) * 5));
	}

  async function changeStart(i) {
		if (start === i) return;
		start = i;
		const t = text;
		text = "";
		await tick();
		text = t;
	}
</script>

{#each text as char, i}
	<span
		style={`font-family: 'redaction-${getFont(i)}'`}
		on:mouseenter={() => changeStart(i)}
	>{char}</span>
{/each}